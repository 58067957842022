import React from "react";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import stars from '../../assets/stars.svg'
import vk from '../../assets/vk.svg'
import tg from '../../assets/telegram.svg'
import { Link } from "react-router-dom";

export default function MobileContacts() {
  return (
    <>
    <div className="contacts" id="contacts">
      <div className="container contacts">
        <div className="contacts__header">
            <img src={stars} alt="about" width={250} height={30} />
            <h2 className="contacts__title SansPro500">Контакты</h2>
        </div>
        <div className="contacts__map">
          <YMaps  className="ymaps">
            <Map defaultState={{ center: [68.975345, 33.088132], zoom: 15,
              controls: ["zoomControl", "fullscreenControl"] }} width={'100%'} height={500} 
              modules={["control.ZoomControl", "control.FullscreenControl"]} >
              <Placemark defaultGeometry={[68.975345, 33.088132]} options={{
                iconColor: 'red',
              }}/>
            </Map>
          </YMaps>
        </div>
        <p className='contacts__posttitle Monrat700'>Свяжитесь с нами:</p>
        <div className='contacts__connection connection'>
        <p className="connection__phone ">
                  <span className="Monrat500">Телефон салона:</span> 
                  <a className="redhover Monrat500" href="tel:+78152424373"> +7 (815) 242 43 73</a> 
                  <br></br>
                  <span className="Monrat500">Прокат оборудования:</span> 
                  <a className="redhover Monrat500" href="tel:+79600251777"> +7 (960) 025 17 77</a> 
                  <br></br>
                  <span className="Monrat500">Звуковое сопровождение:</span> 
                  <a className="redhover Monrat500" href="tel:+79600251777"> +7 (960) 025 17 77</a> 
                  <br></br>
                  <span className="Monrat500">Полный тех. продакшн и организация event проектов:</span> 
                  <a className="redhover Monrat500" href="tel:+79600251777"> +7 (960) 025 17 77</a> 

                </p>
                <p className="connection__email"><span className="Monrat500">E-mail:</span>
                  <br></br>
                  <span className="Monrat500">Салон "Muztrade":</span> <a className="redhover Monrat500" href="mailto:manager@muztrade.com">manager@muztrade.com</a> 
                  <br></br>
                  <span className="Monrat500">Прокат оборудования:</span> <a className="redhover Monrat500" href="mailto:denis@muztrade.com">denis@muztrade.com</a> 
                  <br></br>
                  <span className="Monrat500">Звуковое сопровождение:</span> <a className="redhover Monrat500" href="mailto:denis@muztrade.com">denis@muztrade.com</a> 
                  <br></br>
                  <span className="Monrat500">Организация event проектов:</span> <a className="redhover Monrat500" href="mailto:denis@muztrade.com">denis@muztrade.com</a> 
                </p>
          <p className="connection__address Monrat500">
            Мурманск, Рыбный проезд, д.8
          </p>
          <p className="connection__timetable Monrat500">
            <span className="Monrat500">График работы:</span> <br /> Пн - Пт с 12:00 до 20:00
            <br />
            Сб - Вс с 12:00 до 18:00
          </p>
        </div>
        <p className='contacts__p Monrat500'>Мы в соц.сетях:</p>
        <div className='flex-icons connections__vk'>
          <a href={"https://vk.com/muztrade"} className="">
                  <img
                    src={vk}
                    alt="vk"
                    width={58}
                    height={58}
                  />
          </a>
          <a href={"https://t.me/muztrade_pro"}>
                  <img
                    className="tg"
                    src={tg}
                    alt="vk"
                    width={58}
                    height={58}
                  />
          </a>
        </div>
      </div>
      </div>
    </>
  );
}


